@import url(c:UsersþlipocumentsWebDesignportfolio6srcpartialspartials.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  background-color: #f0f0f000;
  box-sizing: border-box;
  position: relative;
  z-index: -5;
  max-width: 2200px;
}

a {
  text-decoration: none;
  color: black;
  background-color: #ffffff00;
}

a:hover {
  color: #6bc3e9;
  text-decoration: line-through;
}

ul {
  list-style-type: none;
}

.aboutme {
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/*# sourceMappingURL=App.css.map */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 2rem;
}

.header_logo {
  width: 300px;
  height: auto;
  position: absolute;
  left: 20%;
  bottom: 5rem;
  z-index: -1;
  background-color: rgba(187, 26, 26, 0);
}

.header_titles {
  display: flex;
  align-items: center;
}

.header_titles_title {
  font-size: 6rem;
  width: 300px;
  color: #6bc3e9;
  font-family: "Montagu Slab", "arial";
  background-color: rgba(245, 245, 245, 0);
}

.header_titles_subtitle {
  font-size: 1.2rem;
  position: relative;
  min-width: 17rem;
  right: 4rem;
  top: 2rem;
  font-family: "Neucha", cursive;
  background-color: rgba(245, 245, 245, 0);
}

.header_titles_title:hover {
  color: black;
  text-decoration: line-through;
}

.header_links {
  width: 35%;
  min-width: 490px;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  border-top: 2px solid #6bc3e9;
  border-bottom: 2px solid #6bc3e9;
  font-family: "Alegreya SC";
  font-weight: bold;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  position: relative;
  bottom: 4rem;
}

.header_links_link {
  color: #6bc3e9;
}

.header_links_link:hover {
  color: black;
}
/*# sourceMappingURL=header.css.map */
.home__background {
  background-color: rgba(0, 0, 0, 0);
}

.home {
  color: black;
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.home_img1 {
  width: 16rem;
  border: 5px solid #5ed2ec;
  margin-bottom: 2rem;
}

.home_rectangle1 {
  width: 40rem;
  height: 300px;
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  left: 7rem;
}

.home_rectangle1_title {
  background-color: rgba(0, 0, 0, 0);
  width: 30%;
  position: relative;
  right: 2rem;
  font-size: 24px;
  border-bottom: 2px solid #5ed2ec;
  margin-bottom: 1rem;
}

.home_rectangle1_text {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  top: 0rem;
  left: 0rem;
  text-align: center;
}

.home2 {
  color: black;
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home2_rectangle2 {
  width: 60rem;
  height: 15rem;
  background-color: #ecfefe;
  position: relative;
  right: 0rem;
  bottom: 5rem;
  border: 5px solid #5ed2ec;
}

.home2_rectangle2_text_text {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  left: 2rem;
  top: 5rem;
  width: 60rem;
  font-size: 1.4rem;
}

.home2_rectangle2_author_text {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  left: 5rem;
  top: 6rem;
  text-align: center;
}

.home3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.home3_rectangle3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 1000px;
  height: 400px;
  background-image: url(/static/media/felipeweblogoWP.57f84f58.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #ecfefe;
  border: 5px solid #5ed2ec;
}

.home3_rectangle3_text {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  left: 8rem;
  font-size: 3rem;
  font-family: "Neucha", cursive;
}

.img2 {
  width: 35rem;
  position: relative;
  top: 2rem;
  left: 3rem;
  border: 5px solid #5ed2ec;
}

.img3 {
  background-color: #ffffff00;
  position: relative;
  top: 10rem;
  right: 5rem;
}

.home4 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
  font-family: "Neucha", cursive;
  margin-bottom: 0rem;
  background-color: #ecfefe;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.home4_rectangle4 {
  width: 300px;
  height: 100px;
  background-color: #ecfefe;
  position: relative;
  right: 10rem;
  border: 5px solid #5ed2ec;
}

.home4_rectangle5 {
  width: 300px;
  height: 100px;
  background-color: white;
  background-image: url(/static/media/felipeweblogo2single.c79b520c.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  left: 15rem;
  top: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #5ed2ec;
  border-right: 2px solid #5ed2ec;
}

.home4_text {
  background-color: #ffffff00;
  position: relative;
  bottom: 3.8rem;
  right: 3rem;
}

.img4 {
  width: auto;
  position: relative;
  right: 10rem;
  background-color: #ffffff00;
}
/*# sourceMappingURL=home.css.map */
.portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img6 {
  margin: 5rem;
  background-color: rgba(255, 255, 255, 0);
  width: 200px;
}
/*# sourceMappingURL=portfolio.css.map */
